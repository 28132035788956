<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";
    import { onMount } from "svelte";
    import API from "@/services/apiService";
    import environment from "@/services/environment";
    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import LoginOption from "./LoginOption.svelte";
    import Carousel from "../../../components/carousel/Carousel.svelte";
    import { LOGIN_SIGNUP_IMAGES } from "../../../static/constant";

    let isSentCode: boolean = false;
    let title = "Login";

    function signup() {
        navigate("/auth/merchant/signup");
    }

    async function loginWithTokenIfPresent(): Promise<void> {
        /*
         * Check if login token is present in the URL
         *
         * Attempt to login with the token if present.
         *
         * The login token is is a backdoor token passed to the merchant
         * portal by Two admin portal via AdminMerchantResponseSchema.
         *
         */
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        if (token) {
            // Call API to login with token if available
            API.get("/v1/portal/merchant/login", null, {
                "Two-Delegated-Authority-Token": token
            })
                .then((res) => {
                    // Redirect to dashboard
                    navigate("/merchant");
                })
                .catch((err) => {
                    console.error("Failed to login with token", err);
                });
        }
    }

    onMount(async () => {
        await loginWithTokenIfPresent();
        title = $_("login.pageTitleNew", {
            values: { productName: environment.branding.productName }
        });
    });
</script>

<svelte:head>
    <title>{title}</title>
</svelte:head>

<MetaWrapper title={$_("login.title")}>
    <div class="login-container">
        <div class="login-form-container">
            <div class="login-form-main">
                {#if !isSentCode}
                    <div id="theme-logo" class="brand-logo">
                        <img src={environment.branding.logo} alt="logo" />
                    </div>
                    <h2 class="ctn-general-primary">{$_("login.heading")}</h2>
                {/if}
                <LoginOption bind:isSentCode />
                {#if isSentCode}
                    <div style="display: flex;justify-content: center;">
                        <button
                            class="button-tertiary-gray button-lg mt-8"
                            on:click={() => (isSentCode = false)}>{$_("components.cancel")}</button
                        >
                    </div>
                {:else if environment.branding.displayName !== "Achteraf betalen"}
                    <p class="text-sm text-center mt-8">
                        {$_("login.noAccount")}
                        <span on:keypress={signup} on:click={signup} class="cursor-pointer">
                            {$_("components.signUp")}
                        </span>
                    </p>
                {/if}
            </div>

            <div class="m-auto mt-10 text-xs gray mb-10 text-center">
                <a target="_blank" href={environment.branding.privacyPolicyLink}>
                    {$_("components.privacyPolicy")}
                </a>
                <p class="mt-2">
                    &copy; {new Date().getFullYear()}
                    {environment.branding.footerText}
                </p>
            </div>
        </div>
        {#if !isSentCode && environment.branding.displayName !== "Achteraf betalen"}
            <div class="carousel-container">
                <Carousel imageSources={LOGIN_SIGNUP_IMAGES} rotationInterval={7000} />
            </div>
        {/if}
    </div>
</MetaWrapper>

<style>
    :global(body) {
        background-color: var(--primary-25);
    }

    .login-container {
        display: flex;
        height: 100%;
        flex: 1;
    }

    .login-form-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        min-width: 600px;
    }

    .brand-logo {
        margin-bottom: 3rem;
    }

    h2 {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        margin-bottom: 1.5rem;
    }

    .login-form-main {
        width: 100%;
        padding: 1rem 2rem;
        margin: auto;
        max-width: 550px;
    }

    .carousel-container {
        flex: 1;
    }

    @media (max-width: 900px) {
        .carousel-container {
            display: none;
        }
    }
    span {
        color: var(--primary-500);
    }
</style>
